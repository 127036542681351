<template>
  <div>
    <Header />
    <section class="bg-light d-none d-lg-block">
      <div class="container py-4">
        <div class="bg-white rounded-10">
          <div class="d-flex justify-content-start gap-2">
            <div
              class="
                col-6
                p-3
                card
                border-bottom-0 border-top-0
                rounded-20
                border-start-0
                right-radius
              "
            >
              <div class="card-body">
                <div class="d-flex justify-content-start gap-3">
                  <div
                    class="
                      d-flex
                      flex-column
                      gap-1
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      :src="nftMarketplace.logo"
                      width="80px"
                      height="80px"
                      alt=""
                    />
                    <a
                      :href="nftMarketplace.website"
                      class="btn btn-outline-dark btn-sm mt-3"
                      >Website</a
                    >
                  </div>
                  <div class="d-flex flex-column gap-1">
                    <h6 class="mb-0 fw-bold">
                      {{ nftMarketplace.name }}
                    </h6>
                    <p
                      v-html="nftMarketplace.shortDescription"
                      class="mb-0"
                    ></p>

                    <div class="d-flex justify-content-start gap-2 mt-2">
                      <div
                        class="badge bg-dark"
                        v-for="(tags, index) in nftMarketplace.tags"
                        :key="index"
                      >
                        {{ tags.name }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-start gap-3 mt-2">
                      <div
                        v-for="(
                          socialCredit, index
                        ) in nftMarketplace.socialLinks"
                        :key="index"
                      >
                        <center>
                          <a
                            :href="socialCredit.url"
                            class="text-secondary text-decoration-none"
                          >
                            <em
                              :class="'h4 ri-' + socialCredit.type + '-line'"
                            ></em>
                          </a>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                col-6
                p-3
                card
                border-bottom-0 border-top-0
                rounded-20
                border-start-0
                left-radius
              "
            >
              <section class="unSwiper-cards">
                <swiper :options="swiperOption" ref="swiper" class="swiper">
                  <swiper-slide
                    v-for="(nftImages, index) in nftMarketplace.images"
                    :key="index"
                  >
                    <a href="#" @click="openLightboxOnSlide(1)">
                      <img
                        :src="nftImages.thumbnail"
                        width="260px"
                        class="rounded-10 img-fluid"
                        alt=""
                      />
                    </a>
                  </swiper-slide>

                  <template v-slot:button-prev>
                    <div
                      @click="$refs.swiper.swiperInstance.slidePrev()"
                      class="swiper-button-prev"
                    ></div>
                  </template>
                  <template v-slot:button-next>
                    <div
                      @click="$refs.swiper.swiperInstance.slideNext()"
                      class="swiper-button-next"
                    ></div>
                  </template>
                </swiper>

                <BannerFs :productsImages="[nftMarketplace.images]" />
              </section>
            </div>
          </div>
          <div class="d-flex justify-content-start gap-2">
            <div
              class="
                col-6
                p-3
                card
                border-bottom-0 border-top-0
                rounded-20
                border-start-0
                right-radius
              "
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <h6>Volume</h6>
                    <h5 class="fw-bold">
                      ${{ nftMarketplaceStats.volumeInFiat | formatNumber }}
                    </h5>
                    <p
                      v-if="nftMarketplaceStats.volumeInFiatChange > 0"
                      class="text-success"
                    >
                      {{ nftMarketplaceStats.volumeInFiatChange }}%
                    </p>
                    <p v-else class="text-danger">
                      {{ nftMarketplaceStats.volumeInFiatChange }}%
                    </p>
                  </div>

                  <div class="col-4">
                    <h6>Traders</h6>
                    <h5 class="fw-bold">
                      {{
                        Number(
                          nftMarketplaceStats.tradersCount
                        ).toLocaleString()
                      }}
                    </h5>
                    <p
                      v-if="nftMarketplaceStats.tradersCountChange > 0"
                      class="text-success"
                    >
                      {{ nftMarketplaceStats.tradersCountChange }}%
                    </p>
                    <p v-else class="text-danger">
                      {{ nftMarketplaceStats.tradersCountChange }}%
                    </p>
                  </div>

                  <div class="col-4">
                    <h6>Average Price</h6>
                    <h5 class="fw-bold">
                      ${{ nftMarketplaceStats.avgPriceInFiat | formatNumber }}
                    </h5>
                    <p
                      v-if="nftMarketplaceStats.avgPriceInFiatChange > 0"
                      class="text-success"
                    >
                      {{ nftMarketplaceStats.avgPriceInFiatChange }}%
                    </p>
                    <p v-else class="text-danger">
                      {{ nftMarketplaceStats.avgPriceInFiatChange }}%
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <h6>Users</h6>
                    <div class="d-flex justify-content-between gap-3">
                      <div>
                        <h5 class="fw-bold">
                          {{ nftStatistic.userActivity }}
                        </h5>
                        <p
                          v-if="nftStatistic.userActivityChange > 0"
                          class="text-success"
                        >
                          {{
                            Number(
                              nftStatistic.userActivityChange
                            ).toLocaleString()
                          }}%
                        </p>
                        <p v-else class="text-danger">
                          {{
                            Number(
                              nftStatistic.userActivityChange
                            ).toLocaleString()
                          }}%
                        </p>
                      </div>
                      <img
                        v-if="nftStatistic.activeUsersChart !== null"
                        :src="nftStatistic.activeUsersChart"
                        alt=""
                        class="w-50"
                        style="max-height: 35px"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <h6>Transaction</h6>
                    <div class="d-flex justify-content-between gap-3">
                      <div>
                        <h5 class="fw-bold">
                          {{ nftStatistic.transactionCount | formatNumber }}
                        </h5>
                        <p
                          v-if="nftStatistic.transactionChange > 0"
                          class="text-success"
                        >
                          {{
                            Number(
                              nftStatistic.transactionChange
                            ).toLocaleString()
                          }}%
                        </p>
                        <p v-else class="text-danger">
                          {{
                            Number(
                              nftStatistic.transactionChange
                            ).toLocaleString()
                          }}%
                        </p>
                      </div>
                      <img
                        v-if="nftStatistic.transactionsChart !== null"
                        :src="nftStatistic.transactionsChart"
                        alt=""
                        class="w-50"
                        style="max-height: 35px"
                      />
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-6">
                    <h6>Volume</h6>
                    <div class="d-flex justify-content-between gap-3">
                      <div>
                        <h5 class="fw-bold">
                          ${{ nftStatistic.totalVolumeInFiat | formatNumber }}
                        </h5>
                        <p>
                          {{ Number(nftStatistic.volume).toFixed(0) }}
                        </p>
                        <p
                          v-if="nftStatistic.totalVolumeChangeInFiat > 0"
                          class="text-success"
                        >
                          {{
                            Number(
                              nftStatistic.totalVolumeChangeInFiat
                            ).toLocaleString()
                          }}%
                        </p>
                        <p v-else class="text-danger">
                          {{
                            Number(
                              nftStatistic.totalVolumeChangeInFiat
                            ).toLocaleString()
                          }}%
                        </p>
                      </div>
                      <img
                        v-if="nftStatistic.volumeInFiatChart !== null"
                        :src="nftStatistic.volumeInFiatChart"
                        alt=""
                        class="w-50"
                        style="max-height: 35px"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <h6>Balance</h6>
                    <div class="d-flex justify-content-between gap-3">
                      <div>
                        <h5 class="fw-bold">
                          {{ nftStatistic.totalBalanceInFiat }}
                        </h5>
                        <p>
                          {{ Number(nftStatistic.balance).toFixed(5) }}
                        </p>
                      </div>
                      <img
                        v-if="nftStatistic.balanceFiatChart !== null"
                        :src="nftStatistic.balanceFiatChart"
                        alt=""
                        class="w-50"
                        style="max-height: 35px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <apexchart
                type="line"
                ref="nftsChart"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>
        </div>
        <h5 class="mt-4 fw-bold">Similar Marketplace</h5>
        <div class="row">
          <div
            class="col-2"
            v-for="(similar, index) in nftMarketplace.similarDapps"
            :key="index"
          >
            <div class="card rounded-10 mt-4">
              <div class="card-body">
                <img :src="similar.logo" alt="" height="40px" width="40px" />
                <div class="mt-3">
                  <small class="fw-bold">
                    {{ similar.name }}
                  </small>
                </div>
                <small class="text-capitalize">
                  {{ similar.protocol }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="d-lg-none d-block px-2 mb-3">
      <div class="card margin-t-10 border-0">
        <div class="card-body">
          <div class="d-flex justify-content-start gap-3">
            <div
              class="
                d-flex
                flex-column
                justify-content-start
                align-items-center
              "
            >
              <img
                :src="nftMarketplace.logo"
                width="65px"
                height="65px"
                class="mb-4 rounded-3"
                alt=""
              />
              <a
                :href="nftMarketplace.website"
                class="btn btn-outline-dark btn-sm mt-4"
                >Website</a
              >
            </div>
            <div class="d-flex flex-column gap-1">
              <h6 class="mb-0 fw-bold">
                {{ nftMarketplace.name }}
              </h6>
              <p v-html="nftMarketplace.shortDescription" class="mb-0"></p>

              <div class="d-flex justify-content-start gap-2 mt-2">
                <div
                  class="badge bg-dark"
                  v-for="(tags, index) in nftMarketplace.tags"
                  :key="index"
                >
                  {{ tags.name }}
                </div>
              </div>

              <div class="d-flex justify-content-start gap-3 mt-2">
                <div
                  v-for="(socialCredit, index) in nftMarketplace.socialLinks"
                  :key="index"
                >
                  <center>
                    <a
                      :href="socialCredit.url"
                      class="text-secondary text-decoration-none"
                    >
                      <em :class="'h4 ri-' + socialCredit.type + '-line'"></em>
                    </a>
                  </center>
                </div>
              </div>
            </div>
          </div>

          <section class="unSwiper-cards mt-3">
            <swiper :options="swiperOption" ref="swiper" class="swiper">
              <swiper-slide
                v-for="(nftImages, index) in nftMarketplace.images"
                :key="index"
              >
                <a href="#" @click="openLightboxOnSlide(1)">
                  <img
                    :src="nftImages.thumbnail"
                    width="260px"
                    class="rounded-10 img-fluid"
                    alt=""
                  />
                </a>
              </swiper-slide>

              <template v-slot:button-prev>
                <div
                  @click="$refs.swiper.swiperInstance.slidePrev()"
                  class="swiper-button-prev"
                ></div>
              </template>
              <template v-slot:button-next>
                <div
                  @click="$refs.swiper.swiperInstance.slideNext()"
                  class="swiper-button-next"
                ></div>
              </template>
            </swiper>

            <BannerFs :productsImages="[nftMarketplace.images]" />
          </section>

          <div class="card-body px-0 mt-2">
            <div class="d-flex justify-content-start gap-3 custom-scroll">
              <div class="col-10">
                <div class="card rounded-10">
                  <div class="card-body">
                    <h6>Volume</h6>
                    <h5 class="fw-bold">
                      ${{ nftMarketplaceStats.volumeInFiat | formatNumber }}
                    </h5>
                    <p
                      v-if="nftMarketplaceStats.volumeInFiatChange > 0"
                      class="text-success mb-0"
                    >
                      {{ nftMarketplaceStats.volumeInFiatChange }}%
                    </p>
                    <p v-else class="text-danger mb-0">
                      {{ nftMarketplaceStats.volumeInFiatChange }}%
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-10">
                <div class="card rounded-10">
                  <div class="card-body">
                    <h6>Traders</h6>
                    <h5 class="fw-bold">
                      {{
                        Number(
                          nftMarketplaceStats.tradersCount
                        ).toLocaleString()
                      }}
                    </h5>
                    <p
                      v-if="nftMarketplaceStats.tradersCountChange > 0"
                      class="text-success mb-0"
                    >
                      {{ nftMarketplaceStats.tradersCountChange }}%
                    </p>
                    <p v-else class="text-danger mb-0">
                      {{ nftMarketplaceStats.tradersCountChange }}%
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-10">
                <div class="card rounded-10">
                  <div class="card-body">
                    <h6>Average Price</h6>
                    <h5 class="fw-bold">
                      ${{ nftMarketplaceStats.avgPriceInFiat | formatNumber }}
                    </h5>
                    <p
                      v-if="nftMarketplaceStats.avgPriceInFiatChange > 0"
                      class="text-success mb-0"
                    >
                      {{ nftMarketplaceStats.avgPriceInFiatChange }}%
                    </p>
                    <p v-else class="text-danger mb-0">
                      {{ nftMarketplaceStats.avgPriceInFiatChange }}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h6>Users</h6>
                <div class="d-flex justify-content-between gap-3">
                  <div>
                    <h5 class="fw-bold">
                      {{ nftStatistic.userActivity }}
                    </h5>
                    <p
                      v-if="nftStatistic.userActivityChange > 0"
                      class="text-success"
                    >
                      {{
                        Number(
                          nftStatistic.userActivityChange
                        ).toLocaleString()
                      }}%
                    </p>
                    <p v-else class="text-danger">
                      {{
                        Number(
                          nftStatistic.userActivityChange
                        ).toLocaleString()
                      }}%
                    </p>
                  </div>
                  <img
                    :src="nftStatistic.activeUsersChart"
                    alt=""
                    class="w-50"
                    style="max-height: 35px"
                  />
                </div>
              </div>
              <div class="col-12">
                <h6>Transaction</h6>
                <div class="d-flex justify-content-between gap-3">
                  <div>
                    <h5 class="fw-bold">
                      {{ nftStatistic.transactionCount | formatNumber }}
                    </h5>
                    <p
                      v-if="nftStatistic.transactionChange > 0"
                      class="text-success"
                    >
                      {{
                        Number(nftStatistic.transactionChange).toLocaleString()
                      }}%
                    </p>
                    <p v-else class="text-danger">
                      {{
                        Number(nftStatistic.transactionChange).toLocaleString()
                      }}%
                    </p>
                  </div>
                  <img
                    :src="nftStatistic.transactionsChart"
                    alt=""
                    class="w-50"
                    style="max-height: 35px"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <h6>Volume</h6>
                <div class="d-flex justify-content-between gap-3">
                  <div>
                    <h5 class="fw-bold">
                      ${{ nftStatistic.totalVolumeInFiat | formatNumber }}
                    </h5>
                    <p>
                      {{ Number(nftStatistic.volume).toFixed(0) }}
                    </p>
                    <p
                      v-if="nftStatistic.totalVolumeChangeInFiat > 0"
                      class="text-success"
                    >
                      {{
                        Number(
                          nftStatistic.totalVolumeChangeInFiat
                        ).toLocaleString()
                      }}%
                    </p>
                    <p v-else class="text-danger">
                      {{
                        Number(
                          nftStatistic.totalVolumeChangeInFiat
                        ).toLocaleString()
                      }}%
                    </p>
                  </div>
                  <img
                    :src="nftStatistic.volumeInFiatChart"
                    alt=""
                    class="w-50"
                    style="max-height: 35px"
                  />
                </div>
              </div>
              <div class="col-12">
                <h6>Balance</h6>
                <div class="d-flex justify-content-between gap-3">
                  <div>
                    <h5 class="fw-bold">
                      ${{ nftStatistic.totalBalanceInFiat | formatNumber }}
                    </h5>
                    <p>
                      {{ Number(nftStatistic.balance).toFixed(2) }}
                    </p>
                  </div>
                  <img
                    :src="nftStatistic.balanceFiatChart"
                    alt=""
                    class="w-50"
                    style="max-height: 35px"
                  />
                </div>
              </div>
            </div>

            <div class="mt-3">
              <apexchart
                type="line"
                ref="nftsChart"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import BannerFs from "../components/BannerFs.vue";
import Footer from "../components/Footer.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.min.css";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  name: "NftMarketplace",
  components: {
    Header,
    Footer,
    BannerFs,
    Swiper,
    SwiperSlide,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      nftMarketplace: [],
      nftStatistic: [],
      nftMarketplaceStats: [],
      slide: 1,
      moment: moment,
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 10,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      series: [
        {
          name: "Income",
          type: "line",
          data: [760, 1661, 1371, 1748, 1478, 1475],
        },
        {
          name: "Cashflow",
          type: "line",
          data: [
            6571235.87, 11809432.28, 19238482.56, 19266759.93, 17298532.28,
            15804438.25,
          ],
        },
        {
          name: "Revenue",
          type: "line",
          data: [1103, 2749, 2263, 3316, 2365, 2400],
        },
      ],
      chartOptions: {
        chart: {
          stacked: false,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: -20,
          },
        },
        grid: {
          padding: {
            bottom: 0,
            left: 14,
            right: 0,
            top: 0,
          },
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [2, 2, 2],
          curve: "smooth",
        },
        title: {
          text: "XYZ - Stock Analysis (2009 - 2016)",
          align: "left",
          offsetX: 110,
        },
        xaxis: {
          axisBorder: { color: "#EFEFEF", show: true },
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            style: { color: "#C3C3C3" },
          },
          type: "datetime",
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            decimalsInFloat: 0,
            show: true,
            axisBorder: {
              show: true,
              color: "#008FFB",
            },
            labels: {
              style: {
                colors: "#008FFB",
              },
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            title: {
              text: "Income (thousand crores)",
              style: {
                color: "#008FFB",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: "Income",
            opposite: true,
            decimalsInFloat: 0,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#00E396",
            },
            labels: {
              maxWidth: "auto",
              style: {
                colors: "#00E396",
              },
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            title: {
              text: "Operating Cashflow (thousand crores)",
              style: {
                color: "#00E396",
              },
            },
          },
          {
            seriesName: "Revenue",
            opposite: true,
            decimalsInFloat: 0,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FEB019",
            },
            labels: {
              maxWidth: "auto",
              style: {
                colors: "#FEB019",
              },
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            title: {
              text: "Revenue (thousand crores)",
              style: {
                color: "#FEB019",
              },
            },
          },
        ],
        tooltip: {
          theme: "light",
        },
        legend: {
          horizontalAlign: "right",
          offsetY: 5,
          position: "bottom",
          show: true,
          labels: {
            useSeriesColors: true,
          },
        },
      },
    };
  },
  mounted() {
    this.getNftMarketplaceDetails();
    this.getNftStatisticDetails();
    this.getNftCharts();
  },
  created() {
    this.$mixpanel.track(
      "Visit NFT Marketplace Detail " + this.$route.params.address
    );
  },
  methods: {
    getNftMarketplaceDetails() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/marketplace/v2/api/dapp/" +
            this.$route.params.protocol +
            "/marketplaces/" +
            this.$route.params.address +
            "?currency=USD"
        )
        .then((res) => {
          this.nftMarketplace = res.data;
          axios
            .get(
              "https://api-social-data.dinodapps.com/nft/v2.1/marketplace/day?dappId=" +
                res.data.id +
                "&currency=USD&protocol=" +
                this.$route.params.protocol
            )
            .then((res) => {
              this.nftMarketplaceStats = res.data.results[0];
            });
        });
    },
    getNftStatisticDetails() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/marketplace/v2/api/dapp/" +
            this.$route.params.protocol +
            "/marketplaces/" +
            this.$route.params.address +
            "/statistic/day?currency=USD"
        )
        .then((res) => {
          this.nftStatistic = res.data;
        });
    },
    getNftCharts() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/marketplace/v2/api/dapp/" +
            this.$route.params.protocol +
            "/marketplaces/" +
            this.$route.params.address +
            "/chart/week?currency=USD"
        )
        .then((res) => {
          this.series = [
            {
              name: res.data.series[0].name,
              type: "line",
              data: res.data.series[0].data,
            },
            {
              name: res.data.series[1].name,
              type: "line",
              data: res.data.series[1].data,
            },
            {
              name: res.data.series[2].name,
              type: "line",
              data: res.data.series[2].data,
            },
          ];

          this.chartOptions = {
            title: {
              text: this.nftMarketplace?.name + " Statistic",
            },
            xaxis: {
              categories: res.data.xaxis,
              labels: {
                formatter: function (val) {
                  return moment(val).format("MMMM Do");
                },
              },
            },
            yaxis: [
              {
                labels: {
                  style: {
                    colors: "#008FFB",
                  },
                  formatter: function (val) {
                    if (val < 1e3) return val;
                    if (val >= 1e3 && val < 1e5)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e5 && val <= 1e6)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e6 && val <= 1e9)
                      return +(val / 1e6).toFixed(2) + "M";
                    if (val >= 1e9 && val <= 1e12)
                      return +(val / 1e9).toFixed(2) + "B";
                  },
                },
                title: {
                  style: {
                    color: "#008FFB",
                  },
                  text: res.data.series[0].name,
                },
              },
              {
                labels: {
                  style: {
                    colors: "#00E396",
                  },
                  formatter: function (val) {
                    if (val < 1e3) return val;
                    if (val >= 1e3 && val < 1e5)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e5 && val <= 1e6)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e6 && val <= 1e9)
                      return +(val / 1e6).toFixed(2) + "M";
                    if (val >= 1e9 && val <= 1e12)
                      return +(val / 1e9).toFixed(2) + "B";
                  },
                  opposite: true,
                },
                title: {
                  text: res.data.series[1].name,
                  style: {
                    color: "#00E396",
                  },
                },
                opposite: true,
              },
              {
                labels: {
                  style: {
                    colors: "#FEB019",
                  },
                  formatter: function (val) {
                    if (val < 1e3) return val;
                    if (val >= 1e3 && val < 1e5)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e5 && val <= 1e6)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e6 && val <= 1e9)
                      return +(val / 1e6).toFixed(2) + "M";
                    if (val >= 1e9 && val <= 1e12)
                      return +(val / 1e9).toFixed(2) + "B";
                  },
                },
                title: {
                  text: res.data.series[2].name,
                  style: {
                    color: "#FEB019",
                  },
                },
                opposite: true,
              },
            ],
            legend: {
              horizontalAlign: "right",
            },
          };
        });
    },
    openLightboxOnSlide: function (number) {
      this.slide = number;
      this.toggler = !this.toggler;
    },
  },
};
</script>

<style>
.custom-scroll {
  overflow-x: auto;
}
.custom-scroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none;
}
/* Optional: show position indicator in red */
.custom-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
