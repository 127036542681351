<template>
  <div>
    <FsLightbox
      :toggler="toggler"
      :sources="productsImages[productIndex]"
      :key="productIndex"
    />
  </div>
</template>
    
<script>
import FsLightbox from "fslightbox-vue";

export default {
  name: "BannerSlide",
  components: { FsLightbox },
  props: {
    productsImages: [],
  },
  data() {
    return {
      toggler: false,
      productIndex: 0,
    };
  },
};
</script>